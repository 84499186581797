export const template = {
  common:
    'Здравствуйте. Познакомьтесь, пожалуйста, с нашими работами в \n' +
    '\n' +
    'instagram (внесен в список запрещенных сайтов)\n' +
    'https://instagram.com/interior__techno\n' +
    '\n' +
    'Телеграмм\n' +
    'https://t.me/interior_techno \n' +
    '\n' +
    'С уважением,\n' +
    'ПО Интерьер технологии \n' +
    '\n' +
    'Сайт https://interior-techno.ru',
  cost:
    'По стоимости: \n' +
    'Выезд замерщика с образцами цветов и материалов, заключение договора - 1500 (по Москве)\n' +
    'Стоимость изготовления зависит от размеров шкафчика, выбранных материалов и комплектации. Вместе с коробкой, наличниками и фурнитурой в бюджетном варианте лдсп выходит около 10000, в более дорогом до 24-25000 \n' +
    'Доставка 2000 (по Москве)\n' +
    'Монтаж около 7-8000 с расходниками\n' +
    '\n' +
    'Цена примерная, для понимания величины  бюджета. Более точную смету составит замерщик на месте.\n' +
    '\n' +
    'Если решите пригласить замерщика, напишите, пожалуйста точный адрес с этажом и подъездом. Он свяжется в течении 1-2 дней и договорится на удобное время приезда.\n' +
    '\n' +
    'Срок изготовления - 4 недели',
};
