import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PublicLink from './forms/PublicLink'; // Новый компонент
import { Button } from '@material-ui/core';
import { globalConfig } from '../configuration/config';
import { setLink } from '../store/reducers/linkSlice';
import { useDispatch } from 'react-redux';

const InfoModalButton = ({ projectGuid }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${globalConfig.config.common.api}StructureUrls/GetPublicLinkByProjectGuid`,
        {
          params: { projectGuid },
          withCredentials: true,
        },
      );
      if (response.status === 200) {
        dispatch(setLink(response.data));
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } catch (err) {
      const errorMessage = err.response?.data || err.message || 'Ошибка при загрузке данных.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [projectGuid]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      {showButton && (
        <PublicLink /> // Передача данных через props
      )}

      {/* Snackbar для ошибок */}
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" severity="error">
          {error}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InfoModalButton;
