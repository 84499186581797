import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import AuthService from '../../Services/AuthService';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Network from '../../Components/Requests';

const Home = () => {
  const [authService] = useState(new AuthService());
  const [user, setUser] = useState();

  // Extract query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const pr = urlParams.get('pr');
  const variant = urlParams.get('variant');

  useEffect(() => {
    getUser();
  }, []);

  const login = () => {
    authService.login();
  };

  const logout = () => {
    localStorage.removeItem('company');
    authService.logout();
  };

  const getUser = () => {
    const fetchUser = async () => {
      try {
        const result = await new Network().GetUserGuid();
        setUser(result);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchUser();
  };

  return (
    <div className="wrapper">
      {user ? (
        <Redirect
          to={`/app${pr ? `?pr=${pr}` : ''}${variant ? `${pr ? '&' : '?'}variant=${variant}` : ''}`}
        />
      ) : (
        <>
          <header>
            <div className="logo-container">
              <a aria-current="page" className="" href="/">
                System123
              </a>
            </div>
            <Link to="/auth" className="narrow">
              Войти
            </Link>
          </header>
          <main>
            <div className="page-index">
              <div className="top-of-page">
                <div className="circle-container loaded">
                  <svg height="1030" width="1030" className="bg-circle">
                    <circle
                      cx="615"
                      cy="515"
                      r="515"
                      stroke="#9c9c9c"
                      strokeWidth="1"
                      strokeDasharray="3236 3236"
                      fill="none"
                      strokeDashoffset="0"></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="content-container hero-wrapper"></div>
          </main>
        </>
      )}
    </div>
  );
};

export default Home;
