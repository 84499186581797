import axios from 'axios';
import React from 'react';
import { globalConfig } from '../configuration/config';

const clearCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

const logoutUserAccount = async () => {
  try {
    // Завершаем сессию на сервере
    await axios.get(`${globalConfig.config.common.identity}/endsession`, {
      withCredentials: true,
    });

    // Очищаем локальные cookies
    clearCookies();

    // Очищаем локальное и сессионное хранилище
    localStorage.clear();
    sessionStorage.clear();

    // Перенаправляем на страницу авторизации
    window.location.href = '/auth';
  } catch (error) {
    console.error('Ошибка при выходе из аккаунта:', error);
  }
};

const Loguot = () => {
  return (
    <>
      <a id="logout" className="menu-item" onClick={logoutUserAccount}>
        Выйти
      </a>
    </>
  );
};

export default Loguot;
