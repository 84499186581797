import React from 'react';
import Button from '@material-ui/core/Button';
import { globalConfig } from '../configuration/config';

const OpenParentChatButton = ({ parentChatGuid, userCompanies }) => {
  if (!parentChatGuid) {
    return null;
  }

  const handleButtonClick = async () => {
    try {
      const response = await fetch(
        `${globalConfig.config.common.api}Companies/GetCompanyByProjectGuid?projectGuid=${parentChatGuid}`,
        {
          credentials: 'include',
        },
      );

      if (!response.ok) {
        throw new Error('Ошибка при запросе компании');
      }

      const companyData = await response.json();
      const companyFound = userCompanies.some((company) => company.id === companyData.id);

      if (companyFound) {
        localStorage.setItem('company', JSON.stringify({ id: companyData.id }));
        window.location.href = `${globalConfig.config.common.chat}?pr=${parentChatGuid}`;
      } else {
        alert(`Переход в чат компании ${companyData?.name} не доступно`);
      }
    } catch (error) {
      console.error('Ошибка запроса:', error);
      alert(`Произошла ошибка при проверке доступа в чат компании ${parentChatGuid}`);
    }
  };

  return (
    <Button className="bm-item menu-item" onClick={handleButtonClick}>
      Открыть оригинальный чат
    </Button>
  );
};

export default OpenParentChatButton;
