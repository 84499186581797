import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  CircularProgress,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import mainIcon from '../images/main_icon.jpg';

const useStyles = makeStyles((theme) => ({
  modalDropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    width: '500px',
    maxWidth: '100%',
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    border: 'none !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  input: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginLeft: '10px',
  },
  fileError: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  selectedFile: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  uploadMessage: {
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  progressText: {
    marginLeft: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));

const ImportModelsModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fileError, setFileError] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modelName, setModelName] = useState('');
  const [modelNameError, setModelNameError] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const defaultName = selectedFiles.find(
        (file) => file.name.endsWith('.3ds') || file.name.endsWith('.blend'),
      )?.name;
      if (defaultName) {
        setModelName(defaultName.replace(/\.[^/.]+$/, ''));
      }
    }
  }, [selectedFiles]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setSelectedFiles(files);
      setFileError('');
      setUploadMessage('');
      setLoading(false);
      setIsUploadComplete(false);
      setUploadProgress(0);
    }
  };

  const uploadFiles = async () => {
    if (selectedFiles.length === 0) {
      setUploadMessage('Выберите файлы.');
      return;
    }

    if (!modelName.trim()) {
      setModelNameError('Название обязательно для заполнения.');
      return;
    }

    setLoading(true);
    setUploadMessage('');
    setUploadProgress(0);
    setIsUploadComplete(false);

    const storage = localStorage.getItem('company');
    const storageCompany = JSON.parse(storage);

    const formData = new FormData();
    formData.append('IdCompany', storageCompany?.id || 1);
    formData.append('name', modelName);
    selectedFiles.forEach((file) => formData.append('Files', file));

    const maxRetries = 3; // Максимальное количество попыток
    let attempt = 0; // Счетчик попыток

    while (attempt < maxRetries) {
      try {
        await axios.post('https://api.system123.ru/api/Projects/ImportModels', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(progress);
          },
        });

        setUploadMessage(
          <>
            Все файлы успешно загружены. В процессе импорта создастся чат с картинкой
            <img
              src={mainIcon}
              alt="Conversion Icon"
              style={{
                width: '50px',
                height: '50px',
                verticalAlign: 'sub',
                margin: '0 5px',
              }}
            />
            и в нём будет отображаться процесс импорта. Результат импорта будет добавлен в этот же
            чат.
          </>,
        );
        setSelectedFiles([]);
        setModelName('');
        fileInputRef.current.value = '';
        setIsUploadComplete(true);
        return; // Выход из функции после успешной загрузки
      } catch (error) {
        attempt += 1;
        console.error(`Попытка ${attempt} не удалась:`, error); // Логирование ошибки

        if (attempt >= maxRetries) {
          setUploadMessage('Ошибка загрузки файла. Попробуйте снова или обратитесь в поддержку.');
        } else {
          setUploadMessage(`Попытка ${attempt} не удалась, повторяем...`);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (isUploadComplete) {
      props.onCloseMenu();
    }
    setUploadMessage('');
    setSelectedFiles([]);
    setModelName('');
    setUploadProgress(0);
    setIsUploadComplete(false);
  };

  const handleModelNameChange = (e) => {
    setModelName(e.target.value);
    setModelNameError('');
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="import-models-modal-title"
          aria-describedby="import-models-modal-description"
          className={classes.modalDropzone}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}>
          <Fade in={open}>
            <div className={classes.paper}>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <h3 id="import-models-modal-title" style={{ marginBottom: 15 }}>
                Импорт моделей
              </h3>
              <div>
                <TextField
                  label="Название"
                  value={modelName}
                  onChange={handleModelNameChange}
                  error={!!modelNameError}
                  helperText={modelNameError}
                  fullWidth
                  required
                  className={classes.textField}
                />
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className={classes.input}
                  ref={fileInputRef}
                />
                {fileError && <p className={classes.fileError}>{fileError}</p>}
                {selectedFiles.length > 0 && (
                  <p className={classes.selectedFile}>
                    Выбраны файлы: {selectedFiles.map((file) => file.name).join(', ')}
                  </p>
                )}
              </div>
              {uploadMessage && <p className={classes.uploadMessage}>{uploadMessage}</p>}
              {loading && (
                <div className={classes.loadingContainer}>
                  <CircularProgress size={24} />
                  <span className={classes.progressText}>Загрузка: {uploadProgress}%</span>
                </div>
              )}
              <div>
                <Button
                  onClick={isUploadComplete ? handleClose : uploadFiles}
                  disabled={loading}
                  className={classes.submitButton}>
                  {isUploadComplete ? 'OK' : 'Импортировать'}
                </Button>
                {!loading && !isUploadComplete && (
                  <Button onClick={handleClose} className={classes.submitButton}>
                    Отмена
                  </Button>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
        <Button className="bm-item menu-item" onClick={handleOpen}>
          Импорт моделей
        </Button>
      </div>
    </>
  );
};

export default ImportModelsModal;
