import React, { useState } from 'react';
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import QRCode from 'qrcode.react';

const useStyles = makeStyles((theme) => ({
  modalDropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    width: '500px',
    maxWidth: '100%',
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    border: 'none !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  qrCodeContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  link: {
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
  },
  okButton: {
    marginTop: theme.spacing(2),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',
    fontSize: '1.2rem',
    padding: theme.spacing(1, 3),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
}));

const Scan3DModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Button className="bm-item menu-item" onClick={handleOpen}>
          3D-сканирование
        </Button>

        <Modal
          aria-labelledby="3d-scan-modal-title"
          aria-describedby="3d-scan-modal-description"
          className={classes.modalDropzone}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}>
          <Fade in={open}>
            <div className={classes.paper}>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="body1" id="3d-scan-modal-description">
                3D сканирование - это один из способов быстрого импорта прототипа вашего изделия в
                нашу систему. При помощи нашего приложения для мобильного телефона Вы можете
                получить достаточно точную по размерам и пропорциям 3Д модель.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Для того чтобы воспользоваться данной возможностью, установите, пожалуйста, наше{' '}
                <a
                  href="https://apps.apple.com/ru/app/var%D1%81%D1%82%D1%83%D0%B4%D0%B8%D1%8F/id6504860659"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}>
                  iOS приложение
                </a>{' '}
                или отсканируйте QR:
              </Typography>
              <div className={classes.qrCodeContainer}>
                <QRCode value="https://apps.apple.com/ru/app/var%D1%81%D1%82%D1%83%D0%B4%D0%B8%D1%8F/id6504860659" />
              </div>
              <Typography variant="h6">Инструкция:</Typography>
              <Typography variant="body1" component="ul">
                <li>
                  Отсканируйте ваше изделие при помощи нашего приложения. Далее, с помощью функции
                  “Поделиться” скачайте получившуюся модель и загрузите её к нам в систему используя
                  пункт в меню “Импорт моделей”.
                </li>
              </Typography>
              <Typography variant="h6">Важно:</Typography>
              <Typography variant="body1" component="ul">
                <li>
                  Для корректной работы приложения ваше iOS устройство должно обладать процессором
                  A14 или более новым.
                </li>
                <li>
                  Пример поддерживаемых устройств:
                  <ul>
                    <li>
                      Смартфоны: iPhone 12 (mini, Pro, Pro Max) - начиная с октября 2020г. и более
                      новые.
                    </li>
                    <li>Планшеты: iPad Air 4 - начиная с сентября 2020 года и более новые.</li>
                    <li>iPad - начиная с октября 2022 года и более новые.</li>
                  </ul>
                </li>
              </Typography>
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                className={classes.okButton}>
                OK
              </Button>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default Scan3DModal;
