import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../store/reducers/loaderSlice';

const Loader = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();

  const handleStartLoading = () => {
    dispatch(startLoading());
  };

  const handleStopLoading = () => {
    dispatch(stopLoading());
  };

  return (
    <div>
      {isLoading && (
        <div className="preloader">
          <div className="preloader-content">
            <div className="loader-circle"></div>
            <div className="loading-text">Загрузка...</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Loader;
