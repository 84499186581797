import React, { useState, useEffect } from 'react';

const ImageViewer = ({ guidChat, isOpen, imageUrl, onClose }) => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(imageUrl);
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchImages = async () => {
    try {
      const response = await fetch(
        `https://api.system123.ru/api/ChatMessages/GetPicturesByByChatGuid?chatGuid=${guidChat}`,
        {
          method: 'GET',
          credentials: 'include',
        },
      );

      const data = await response.json();
      if (Array.isArray(data)) {
        setImages(data);
        const initialImageIndex = data.findIndex((img) => img === imageUrl);
        if (initialImageIndex !== -1) {
          setImageIndex(initialImageIndex);
          setCurrentImage(data[initialImageIndex]);
        } else {
          console.warn('Картинка не найдена в массиве данных, используем первую по умолчанию');
          setImageIndex(0);
          setCurrentImage(data[0] || '');
        }
      }
    } catch (error) {
      console.error('Ошибка при получении картинок:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && guidChat) {
      fetchImages();
    }
  }, [isOpen, guidChat]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        nextImage();
      } else if (e.key === 'ArrowLeft') {
        prevImage();
      } else if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, imageIndex]);

  const nextImage = () => {
    if (imageIndex < images.length - 1) {
      const nextIndex = imageIndex + 1;
      setImageIndex(nextIndex);
      setCurrentImage(images[nextIndex]);
    }
  };

  const prevImage = () => {
    if (imageIndex > 0) {
      const prevIndex = imageIndex - 1;
      setImageIndex(prevIndex);
      setCurrentImage(images[prevIndex]);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen || loading) return null;

  return (
    <div className="image-viewer-overlay" onClick={handleOverlayClick}>
      <div className="image-viewer">
        <button className="close-btn" onClick={onClose}>
          X
        </button>

        {imageIndex > 0 && (
          <button className="arrow prev" onClick={prevImage}>
            <span>←</span>
          </button>
        )}

        <img src={currentImage} alt="Full size" className="image-viewer-img" />

        {imageIndex < images.length - 1 && (
          <button className="arrow next" onClick={nextImage}>
            <span>→</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageViewer;
